<template>
  <div class="component-sec-menu"
       :class="{folded: folded}">
    <div class="sec-menu-content-main">
      <div class="sec-menu-content">
        <slot/>
      </div>
    </div>
    <div class="sec-menu-sizenav">
      <div class="linenav"></div>
      <button @click="toggle" class="btn-toggle">
        <i class="fa" :class="{'fa-chevron-left': folded, 'fa-chevron-right': !folded}"/>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecMenu",
  data() {
    return {
      folded: false
    }
  },
  mounted() {
    document.querySelector('.u-erp-layout-container > div').prepend(this.$el)
  },
  beforeDestroy() {
    let node = document.querySelector('.u-erp-layout-container > div')
    node && node.contains(this.$el) && node.removeChild(this.$el)
  },
  methods: {
    toggle() {
      this.folded = !this.folded
    }
  }
}
</script>

