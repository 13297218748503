export default function (type = 1) {
  let window = 'pessoas.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Adicionar pessoa',
    width: '400',
    height: '600',
    minHeight: '400px',
    backdrop: false,
    move: true,
    clickOutside: true,
    windowClass: 'erp-window-modern v2',
    contentClass: '',
    props: {
      router: this.$router,
      type: type === 1 || type === 2 ? type : 1
    }
  }, () => import('../../pessoa/components/window/SimpleNew.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        created: (wid, data) => {
          console.log('Simple Person created event')
          this.newPersonCreated && this.newPersonCreated(data)
        }
      })
    }) // return wid
}
