import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 5
export const listName = 'pessoas.lista'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
    {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 1},
    {label: 'Responsável', name: 'responsavel', active: false, sortable: true, ordering: 1},
    {label: 'Classificação', name: 'classificacao', active: true, sortable: true, ordering: 1},
    {label: 'Organização', name: 'organizacao', active: true, sortable: true, ordering: 1},
    {label: 'E-mail', name: 'email', active: true, sortable: true, ordering: 1},
    {label: 'Telefone', name: 'telefone', active: true, sortable: true, ordering: 1},
    {label: 'Negócios fe...', alt: 'Negócios fechados', name: 'negocios-fechados', active: false, sortable: true, ordering: 1},
    {label: 'Negócios em...', alt: 'Negócios em andamento', name: 'negocios-andamento', active: false, sortable: true, ordering: 1},
    {label: 'Status', name: 'status', active: true, sortable: true, ordering: 1},
], listVersion)
